import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useSuccessStoriesStore = defineStore("successstories", {
  state: () => ({
    successStories: [],
    correspondingBranches: [],
  }),
  actions: {
    async updateSuccessStories(filter = "*", limit = -1, sort = ["date"]) {
      var options = {
        fields: ["*.*"],
        limit: limit,
        sort: sort,
      };

      filter ? (options.filter = filter) : null;

      const resp = await directus.request(
        readItems("success_stories", options)
      );

      this.successStories = resp;
      this.successStories.forEach(
        (item) => (item.category = "success_stories")
      );
    },
    async updateCorrespondingBranches() {
      const resp = await directus.request(
        readItems("success_stories_branches")
      );

      this.correspondingBranches = resp;
    },
  },
});
