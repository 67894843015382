<template>
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2426 19.1395C16.3397 19.1395 15.6052 18.404 15.6052 17.5005C15.6052 16.5998 16.3397 15.8668 17.2426 15.8668C18.1444 15.8668 18.8779 16.5998 18.8779 17.5005C18.8779 18.404 18.1444 19.1395 17.2426 19.1395Z"
      fill="white"
    />
    <path
      d="M8.83116 19.1395C7.93027 19.1395 7.19727 18.404 7.19727 17.5004C7.19727 16.6003 7.93027 15.8677 8.83116 15.8677C9.73304 15.8677 10.467 16.6003 10.467 17.5004C10.467 18.404 9.73304 19.1395 8.83116 19.1395Z"
      fill="white"
    />
    <path
      d="M11.1145 2.86461C11.1145 2.86461 11.1144 2.86461 11.1144 2.86461C10.5655 2.86461 10.1177 3.31229 10.1177 3.86251C10.1177 4.41484 10.5655 4.86141 11.1144 4.86141H20.6588L20.1273 5.86029H13.252C13.252 5.86029 13.252 5.86031 13.252 5.86031C12.7031 5.86031 12.2553 6.30788 12.2553 6.8582C12.2553 7.41044 12.7031 7.8571 13.252 7.8571H19.0649L18.534 8.855H11.1145C11.1145 8.855 11.1144 8.855 11.1144 8.855C10.5655 8.855 10.1177 9.30259 10.1177 9.85289C10.1177 10.4051 10.5655 10.8517 11.1144 10.8517H11.1144L17.4716 10.8517L16.9448 11.8417H9.13368L3.32668 0.860474H0V2.87297H2.11599L7.92296 13.8543H18.1525L24 2.86461H11.1145Z"
      fill="white"
    />
  </svg>
</template>
