import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";
import { usePreferencesStore } from "./preferences";

export const usePageStore = defineStore("pages", {
  state: () => ({
    page: null,
  }),
  actions: {
    async updatePage(key) {
      if (!key) {
        key = 7;
      } //Wenn nichts übergeben, dann Homepage

      const resp = await directus.request(
        readItems("pages", {
          fields: ["*.*", "blocks.*", "blocks.item.*.*.*.*.*"],
          filter: { id: { _eq: key } },
          limit: 1,
        })
      );

      this.page = resp[0];
    },
    async searchDirectus(searchString) {
      const preferencesStore = usePreferencesStore();
      const lang = preferencesStore.language;
      const url =
        import.meta.env.VITE_DIRECTUS_URL +
        "/search?" +
        new URLSearchParams({ searchString: searchString, languageCode: lang });
      return await fetch(url).then((response) => response.json());
    },
  },
});
