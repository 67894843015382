import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useFaqStore = defineStore("faq", {
  state: () => ({
    faq_list: [],
    faq_topics: [],
  }),

  actions: {
    async updateFaqList(filter, limit = -1, sort = ["sort"]) {
      var options = {
        fields: ["*.*"],
        limit: limit,
        sort: sort,
      };

      filter ? (options.filter = filter) : null;

      const resp = await directus.request(readItems("faq", options));
      this.faq_list = resp;
      this.faq_list.forEach((element, index) => {
        element.active = index === 0;
      });
    },
    async updateFaqTopics() {
      const resp = await directus.request(
        readItems("faq_topics", {
          fields: ["*.*"],
        })
      );
      this.faq_topics = resp;
    },
  },
});
