import { createDirectus, staticToken, rest } from "@directus/sdk";
const directusUrl = import.meta.env.VITE_DIRECTUS_URL;
let token = null;
if ("build" == import.meta.env.VITE_ENVIRONMENT) {
  token = import.meta.env.VITE_DIRECTUS_TOKEN;
}
if ("draft" == import.meta.env.VITE_ENVIRONMENT) {
  token = import.meta.env.VITE_DIRECTUS_DRAFT_TOKEN;
}

const directus = createDirectus(directusUrl)
  .with(staticToken(token))
  .with(rest());

export { directus };
