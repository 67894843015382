import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";
import { getShorthandLang } from "../services/helpers";

export const SUPPORT_LOCALES = {
  de: "Deutsch",
  en: "English",
};

const options = {
  globalInjection: true,
  legacy: false,
  locale: "de",
  fallbackLocale: "de",
  messages: messages,
  seo: true,
};

const i18n = createI18n(options);

setupI18n();

async function setupI18n(locale = "de") {
  locale = typeof window != "undefined" ? localStorage.getItem("lang") : locale;
  if (!locale) {
    if (Object.keys(SUPPORT_LOCALES).includes(navigator.language)) {
      locale = navigator.language;
    } else {
      locale = options.fallbackLocale;
    }
  }

  if (!i18n.global.availableLocales.includes(locale)) {
    // await loadLanguage(i18n, locale)
  }

  if (i18n.mode === "legacy") {
    i18n.global.locale = getShorthandLang(locale);
  } else {
    i18n.global.locale.value = getShorthandLang(locale);
  }
  return i18n;
}

export { setupI18n };

export default i18n;
