import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useProductWorldStore = defineStore("productworld", {
  state: () => ({
    product_worlds: [],
  }),
  getters: {
    getProductWorldById: (state) => {
      return (id) => state.product_worlds.find((item) => item.id === id);
    },
  },
  actions: {
    async updateProductWorlds() {
      const resp = await directus.request(
        readItems("branches", {
          fields: ["*.*"],
        })
      );
      this.product_worlds = resp;
    },
  },
});
