import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useEventsStore = defineStore("events", {
  state: () => ({
    events: [],
  }),

  actions: {
    async updateEvents(filter, limit = -1, sort = ["start_date"]) {
      var options = {
        fields: ["*.*"],
        limit: limit,
        sort: sort,
      };

      filter ? (options.filter = filter) : null;

      const resp = await directus.request(readItems("events", options));

      this.events = resp;
      this.events.forEach((item) => (item.category = "events"));
    },
  },
});
