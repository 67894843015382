import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useProductListStore = defineStore("productlist", {
  state: () => ({
    product_list: [],
  }),
  getters: {
    getProductById: (state) => {
      return (id) => state.product_list.find((item) => item.id === id);
    },
  },
  actions: {
    async updateProductList() {
      const resp = await directus.request(
        readItems("product_categories", {
          fields: ["*.*"],
        })
      );
      this.product_list = resp;
    },
  },
});
