<template>
  <header :class="{ 'header-shadow py-1': !onTop, 'sticky-header': isSticky }">
    <div class="max-container">
      <div
        class="container d-flex align-items-stretch justify-content-between position-static"
      >
        <router-link
          class="link py-4"
          :to="{
            name:
              preferencesStore.language == 'de-DE' ? 'HOME' : 'ENGLISH HOME',
          }"
          :aria-label="t('arialabel.home')"
          ><main-logo
            :data-width="200"
            class="ms-xl-0"
            :class="{ 'mx-auto': mobileNavOpen }" /></router-link
        ><responsive-menu />
      </div>
    </div>
  </header>
  <side-navigation :mobile="mobileNavOpen" />
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { usePreferencesStore } from "../stores";
import MainLogo from "./LogoSvg.vue";
import ResponsiveMenu from "../components/ResponsiveMenu.vue";
import { mobileNavOpen } from "../services/helpers";
import SideNavigation from "./SideNavigation.vue";
import { RouterLink } from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
const preferencesStore = usePreferencesStore();

const onTop = ref(true);
const isSticky = ref(false);
const lastScroll = ref(0);

const onScroll = () => {
  var a = window.scrollY;
  a > 150 ? (onTop.value = false) : (onTop.value = true);
  a > lastScroll.value && a > 200
    ? (isSticky.value = false)
    : (isSticky.value = true);
  lastScroll.value = a;
};

onMounted(() => {
  window.addEventListener("scroll", onScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", onScroll);
});
</script>
<style scoped>
.max-container {
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
}

header {
  background: #fff;
  transition: all 300ms;
  transition-delay: 500ms;
}

.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  opacity: 1 !important;
}

.header-shadow {
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 300ms;
}
</style>
