<template>
  <nav v-if="menuStore.main_menu" class="d-lg-flex align-items-center">
    <ul
      ref="nav_list"
      class="list-unstyled d-block m-0 d-lg-flex position-static"
    >
      <template v-for="(item, index) in menuStore.main_menu" :key="index">
        <li
          class="p-2 d-lg-inline-flex flex-lg-column align-items-center justify-content-lg-between item-wrapper"
          v-if="item.enabled"
        >
          <span class="position-relative h-100 d-lg-flex align-items-stretch">
            <router-link
              :to="item.full_slug"
              class="text-uppercase page-link text-lg-center"
              :data-text="item.title"
              ><span class="d-flex h-100 align-items-center">{{
                item.title
              }}</span></router-link
            >
            <a
              class="mobileSubNavToggle d-lg-none inherit"
              v-if="
                item.sub_items && item.sub_items.find((item) => item.enabled)
              "
              href="#"
              @click.prevent="toggleMobileSubNavigation('second', index)"
              aria-label="Toggle Sub Navigation"
            >
              <bs-icon icon="chevron-right"></bs-icon>
            </a>
          </span>
          <!-- SECOND LAYER -->
          <div
            class="sub-navigation"
            v-if="item.sub_items && item.sub_items.find((item) => item.enabled)"
            :class="{ hiddenOnMobileIfNotActive: index != secondLayerIndex }"
          >
            <div class="sub_wrapper d-lg-flex">
              <ul
                class="list-unstyled d-block d-lg-inline-block ms-auto columns-2 me-lg-5"
              >
                <li class="m-2 m-lg-0 d-flex item-wrapper d-lg-none">
                  <a
                    class="mobileSubNavToggle_left inherit"
                    v-if="
                      item.sub_items &&
                      item.sub_items.find((item) => item.enabled)
                    "
                    href="#"
                    @click.prevent="toggleMobileSubNavigation('second', index)"
                    aria-label="Toggle Sub Navigation reverse"
                  >
                    <bs-icon icon="chevron-left"></bs-icon>
                  </a>
                  <h3 class="px-4 mb-5">
                    <strong>{{ item.title }}</strong>
                  </h3>
                </li>
                <li
                  class="m-2 m-lg-0 item-wrapper"
                  v-for="(sub_item, subIndex) in item.sub_items"
                  :key="subIndex"
                >
                  <span
                    class="position-relative d-lg-block"
                    v-if="sub_item.enabled"
                  >
                    <router-link
                      :to="sub_item.full_slug"
                      class="text-uppercase page-link text-lg-left p-2 d-lg-inline-block"
                      :data-text="sub_item.title"
                      >{{ (subIndex + 1).toString().padStart(2, "0") }}
                      <span class="ms-2 d-inline-block"
                        >{{ sub_item.title }}
                        <span class="hidden">{{ sub_item.title }}</span>
                      </span></router-link
                    >
                    <a
                      class="mobileSubNavToggle d-lg-none inherit"
                      v-if="sub_item.third_items"
                      href="#"
                      @click.prevent="
                        toggleMobileSubNavigation('third', index, subIndex)
                      "
                      aria-label="Toggle Sub Navigation right"
                    >
                      <bs-icon icon="chevron-right"></bs-icon>
                    </a>
                  </span>
                  <!-- THIRD LAYER -->
                  <div
                    class="sub-navigation d-lg-none"
                    v-if="sub_item.third_items"
                    :class="{
                      hiddenOnMobileIfNotActive: subIndex != thirdLayerIndex,
                    }"
                  >
                    <ul class="list-unstyled d-block m-0">
                      <li class="m-2 m-lg-0 d-flex d-lg-none">
                        <a
                          class="mobileSubNavToggle_left inherit"
                          v-if="sub_item.third_items"
                          href="#"
                          @click.prevent="
                            toggleMobileSubNavigation('third', index)
                          "
                          aria-label="Toggle Sub Navigation left"
                        >
                          <bs-icon icon="chevron-left"></bs-icon>
                        </a>
                        <h3 class="px-4 mb-5">
                          <strong
                            >{{ item.title }} / {{ sub_item.title }}</strong
                          >
                        </h3>
                      </li>
                      <li
                        class="m-2 m-lg-0"
                        v-for="(
                          sub_sub_item, subsubindex
                        ) in sub_item.third_items"
                        :key="subsubindex"
                      >
                        <span
                          class="position-relative"
                          v-if="sub_sub_item.enabled"
                        >
                          <router-link
                            :to="sub_sub_item?.full_slug"
                            class="text-uppercase page-link text-lg-center p-2"
                            :data-text="sub_sub_item.title"
                            >{{
                              (subsubindex + 1).toString().padStart(2, "0")
                            }}
                            <span class="ms-3">{{
                              sub_sub_item.title
                            }}</span></router-link
                          >
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <a
                href="#"
                @click.prevent="addTempNoHover"
                class="inherit d-none d-lg-block"
              >
                <bs-icon icon="x-lg" class="me-5 cursor-pinter" :size="32" />
              </a>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </nav>
</template>
<script setup>
import { ref, watch } from "vue";
import { useMenuStore } from "../stores";

import bsIcon from "./icons/bs-icon.vue";

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const menuStore = useMenuStore();
const nav_list = ref();
const secondLayerIndex = ref(null);
const thirdLayerIndex = ref(null);

const toggleMobileSubNavigation = (layer, index, subIndex) => {
  layer == "second"
    ? nav_list.value.classList.toggle("second")
    : nav_list.value.classList.toggle("third");

  secondLayerIndex.value = index;
  thirdLayerIndex.value = subIndex;
};

const addTempNoHover = (e) => {
  const t = e.currentTarget.parentNode.parentNode.parentNode;
  t.classList.add("no-hover");
  window.setTimeout(() => {
    var elements = document.getElementsByClassName("no-hover");

    [].forEach.call(elements, function (el) {
      el.classList.remove("no-hover");
    });
  }, 1000);
};

watch(
  () => props.open,
  (n) => {
    if (!n) {
      nav_list.value.classList.remove("second", "third");
      secondLayerIndex.value = null;
      thirdLayerIndex.value = null;
    }
  }
);
</script>

<style scoped>
nav {
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
  position: static;
}
nav ul {
  transition: transform 300ms ease-in-out;
}

.item-wrapper {
  position: static;
}
.item-wrapper .page-link {
  padding: 0.5rem;
  margin-bottom: 1px;
}
.item-wrapper .mobileSubNavToggle {
  z-index: 10;
}

.page-link {
  color: inherit;
  transition: all 200ms;
  white-space: nowrap;
}
.page-link:hover {
  font-weight: bold;
}
.page-link::after {
  content: attr(data-text);
  content: attr(data-text) / "";
  height: 0px;
  display: block;
  visibility: visible;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-weight: bold;
}

.router-link-active {
  font-weight: 700;
  color: var(--saka_blue);
}

.sub-navigation .router-link-active {
  font-weight: 400;
}

.mobileSubNavToggle {
  position: absolute;
  top: 0;
  right: 0rem;
  bottom: 0;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-navigation {
  position: absolute;
  top: 0;
  left: calc(100% + 1rem);
  width: 100%;
  z-index: 10000;
}

@media (max-width: 991px) {
  .hiddenOnMobileIfNotActive {
    display: none;
  }

  nav ul.second {
    transform: translateX(-100%);
  }
  nav ul.third {
    transform: translateX(-200%);
  }
  .hidden {
    display: none;
  }
}

@media (min-width: 992px) {
  nav {
    overflow: visible;
  }

  .sub_wrapper {
    padding: 3rem 0;
    background: rgba(245, 245, 245, 0.95);
  }

  .item-wrapper .page-link {
    padding: 0;
  }
  .sub-navigation {
    top: 5rem;
    padding-top: 3.25rem;
    position: absolute;
    left: 3rem;
    right: 3rem;
    z-index: -1;
    transform: translate(0%, -500%);
    opacity: 0;
    overflow: visible;
    transition: opacity 500ms;
    width: auto;
  }

  /* .sub-navigation .sub-navigation {
    right: calc(100% + 1px);
    left: auto;
    transform: translate(0, 0) !important;
    top: 0;
  } */

  .sub-navigation ul li a > span {
    font-size: 1.5rem;
    transition: color 300ms;
    margin-left: 1rem;
  }

  .sub-navigation ul li a:hover > span {
    color: var(--saka_blue);
  }
  /* li:first-child > .sub-navigation, */
  li:not(.no-hover):hover > .sub-navigation {
    display: block;
    opacity: 1;
    z-index: 1;
    transform: translate(0, 0);
  }

  .hidden {
    display: block;
    font-weight: 900;
    margin-top: -2rem;
    visibility: hidden;
    margin-right: 2.5rem;
    line-height: 1;
  }

  .columns-2 {
    columns: 2;
  }

  .gap-50 {
    gap: 100px;
  }
}
</style>
