<template>
  <div class="navigation-wrapper" :class="{ 'mobile-open': mobileNavOpen }">
    <main-navigation :open="mobileNavOpen" />
  </div>
  <button
    type="button"
    class="no-style d-lg-none toggle-navigation"
    :class="{ 'mobile-open': mobileNavOpen }"
    @click.prevent="toogleMobileNavigation"
    aria-label="toggle navigation"
  >
    <bs-icon :icon="getToggleIcon()" :size="32" :color="getColorIcon()" />
  </button>
</template>
<script setup>
import { watch } from "vue";
import BsIcon from "./icons/bs-icon.vue";
import MainNavigation from "./MainNavigation.vue";
import { mobileNavOpen } from "../services/helpers";
import { useWindowSize } from "@vueuse/core";

const { width } = useWindowSize();

const toogleMobileNavigation = () => {
  mobileNavOpen.value = !mobileNavOpen.value;
};

const getToggleIcon = () => (mobileNavOpen.value ? "x-lg" : "menu");
const getColorIcon = () => (mobileNavOpen.value ? "#fff" : "#000");

watch(width, () => {
  mobileNavOpen.value = false;
  document.body.classList.remove("overflow-hidden");
});
</script>
<style scoped>
/* mobile navigation wrapper design*/
.navigation-wrapper {
  display: none;
  transition: all 300ms;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 8rem 2rem;
  border-right: 3rem solid var(--saka_blue);
}
@media (min-width: 992px) {
  .navigation-wrapper {
    display: block;
    position: static;
    background: none;
    left: auto;
    width: auto;
    height: auto;
    top: auto;
    border: none;
    padding: 0;
  }
}

.navigation-wrapper.mobile-open {
  display: block;
}

.toggle-navigation.mobile-open {
  position: fixed;
  z-index: 1002;
  right: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
}
</style>
