import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useNewsStore = defineStore("news", {
  state: () => ({
    news: [],
  }),
  actions: {
    async updateNews(filter, limit = -1, sort = ["date"]) {
      var options = {
        fields: ["*.*"],
        limit: limit,
        sort: sort,
      };

      filter ? (options.filter = filter) : null;

      const resp = await directus.request(readItems("news", options));

      this.news = resp;
      this.news.forEach((item) => (item.category = "news"));
    },
  },
});
