import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";
import { useMenuStore } from "../stores";
// import { setupI18n } from "../services/i18n";

export const usePreferencesStore = defineStore("preferences", {
  state: () => ({
    language:
      typeof window != "undefined"
        ? localStorage.getItem("lang") || "de-DE"
        : "de-DE",
    givenLanguages: [],
    buttonTypes: [],
  }),
  getters: {},
  actions: {
    async updateLanguage(language) {
      return new Promise((resolve) => {
        this.language = language;
        typeof window != "undefined"
          ? localStorage.setItem("lang", language)
          : null;
        const promises = [];
        // promises.push(setupI18n(getShorthandLang(language)));
        //reinit all components data
        const menuStore = useMenuStore();
        promises.push(menuStore.updateAllNavigations());
        Promise.all(promises).then(() => {
          resolve();
        });
      });
    },
    async updateGivenLanguages() {
      const resp = await directus.request(
        readItems("languages", {
          fields: ["code"],
        })
      );
      this.givenLanguages = resp;
    },
    async updateButtonTypes() {
      const resp = await directus.request(readItems("button_types"));
      this.buttonTypes = resp;
    },
  },
});
