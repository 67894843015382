import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useSpecialistContributionsStore = defineStore(
  "specialistContributions",
  {
    state: () => ({
      specialistContributions: [],
    }),
    actions: {
      async updateSpecialistContributions(filter, limit = -1, sort = ["date"]) {
        var options = {
          fields: ["*.*"],
          limit: limit,
          sort: sort,
        };

        filter ? (options.filter = filter) : null;
        const resp = await directus.request(
          readItems("specialist_contributions", options)
        );
        this.specialistContributions = resp;
        this.specialistContributions.forEach(
          (item) => (item.category = "specialist_contributions")
        );
      },
    },
  }
);
