import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useTestimonialStore = defineStore("testimonials", {
  state: () => ({
    testimonials: [],
  }),
  actions: {
    async updateTestimonials() {
      const resp = await directus.request(
        readItems("testimonials", {
          fields: ["*.*"],
        })
      );

      this.testimonials = resp;
    },
  },
});
