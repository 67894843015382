import { ref, watch, computed } from "vue";

import {
  usePreferencesStore,
  useMenuStore,
  useProductWorldStore,
  useFaqStore,
} from "../stores";
const isLoading = ref(true);

/* get class for buttons */
const getBtnClass = (id) => {
  const preferencesStore = usePreferencesStore();
  return (
    preferencesStore.buttonTypes.find((item) => item.id === id)?.type ||
    "btn-primary"
  );
};

/*convert to plain text*/
const convertToPlainText = (text) => {
  if (text) {
    return text.replace(/<[^>]+>/g, "");
  }
  return "";
};

/* get image url by id */
const getImageUrl = (id) => {
  return id ? "https://cdn.sachsenkabel.de/assets/" + id : null;
};

/* gets faq topic title by id */
const getFaqTopicTitleById = (id) => {
  const preferencesStore = usePreferencesStore();
  const lang = computed(() => preferencesStore.language);

  if (!id) return false;
  const faqStore = useFaqStore();
  const el = faqStore.faq_topics.find((item) => item.id === id);
  return el
    ? el.translations.find((item) => item.languages_code == lang.value)?.title
    : "";
};

/* gets full slug of page by id */
const getLinkForPageID = (id) => {
  if (!id) return false;
  const menuStore = useMenuStore();
  return menuStore.getSlugById(id);
};

/* gets productworld by id */
const getProductWorldByID = (id) => {
  if (!id) return false;
  const productworldStore = useProductWorldStore();
  return productworldStore.getProductWorldById(id);
};

/* Mobile Nav const and function  */
const mobileNavOpen = ref(false);

/* Searchbar const and function  */
const showSearch = ref(false);
const toggleShowSearch = () => {
  showSearch.value = !showSearch.value;
};

const getLanguageFromString = (language) => {
  switch (language) {
    case "de-DE":
      return "deu".toUpperCase();
    case "en-US":
      return "eng".toUpperCase();
    default:
      return "deu".toUpperCase();
  }
};

const getShorthandLang = (lang) => {
  switch (lang) {
    case "de-DE":
      return "de";
    case "en-US":
      return "en";
    default:
      return "de";
  }
};

//set Class to body if mobile nav open
watch(mobileNavOpen, (state) => {
  state
    ? document.body.classList.add("overflow-hidden")
    : document.body.classList.remove("overflow-hidden");
});

// Checks if link starts with https://
const linkStartsWithHttps = (link) => {
  //set true if link starts width 'mailto:' or 'tel:' or 'https://' or 'http://' link!!!

  return (
    link.startsWith("http://") ||
    link.startsWith("https://") ||
    link.startsWith("mailto:") ||
    link.startsWith("tel:")
  );
};

export {
  isLoading,
  getBtnClass,
  getImageUrl,
  getFaqTopicTitleById,
  getLinkForPageID,
  getProductWorldByID,
  toggleShowSearch,
  mobileNavOpen,
  showSearch,
  getLanguageFromString,
  getShorthandLang,
  convertToPlainText,
  linkStartsWithHttps,
};
