import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const usePressReleasesStore = defineStore("pressReleases", {
  state: () => ({
    pressReleases: [],
  }),
  actions: {
    async updatePressReleases(filter, limit = -1, sort = ["date"]) {
      var options = {
        fields: ["*.*"],
        limit: limit,
        sort: sort,
      };

      filter ? (options.filter = filter) : null;

      const resp = await directus.request(readItems("press_releases", options));
      this.pressReleases = resp;
      this.pressReleases.forEach((item) => (item.category = "press_releases"));
    },
  },
});
